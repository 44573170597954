<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <v-card>
      <button
        type="button"
        class="v-btn v-btn--absolute v-btn--fab v-btn--has-bg v-btn--right v-btn--round v-btn--top theme--light v-size--default primary"
        title="Vložit aktualitu"
        @click="open_form_clanky=true"
      >
        <span
          class="v-btn__content"
        >
          <span
            aria-hidden="true"
            class="v-icon notranslate theme--light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              role="img"
              aria-hidden="true"
              class="v-icon__svg"
            >
              <path
                d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
              >
              </path>
            </svg>
          </span>
        </span>
      </button>
    </v-card>
    <v-card
      :loading="loading"
      class="news-card"
      :style="`height:${desktop_h - boxH}px`"
    >
      <v-row class="ma-0 pa-0">
        <v-col
          xl="8"
          lg="7"
          sm="8"
          md="8"
          xs="12"
        >
          <v-card-title class="text-no-wrap pt-1 ps-2">
            Aktuality
          </v-card-title>

          <v-card-subtitle
            class="text-no-wrap d-flex pb-2 ps-2"
            style="margin-left: 10px;"
          >
            Důležité firemní oznámení
          </v-card-subtitle>
        </v-col>
      </v-row>

      <div style="padding-bottom:15px;">
        <div
          :style="`overflow-y: scroll;height:${desktop_h - boxH1}px`"
        >
          <div
            v-for="(clanok, index) in clanky"
            :key="index"
          >
            <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
              <v-col
                xl="2"
                lg="2"
                sm="2"
                md="2"
                xs="12"
              >
                <div style="text-align:center">
                  <v-avatar
                    size="60px"
                  >
                    <v-img
                      v-if="clanok.userinfo && clanok.userinfo.length>0 && clanok.userinfo[0].foto !== null"
                      :src="`${clanok.userinfo[0].foto}`"
                    ></v-img>
                    <v-img
                      v-else
                      :src="`${!isWoman(clanok.autor[0].prijmeni) ? require('@/assets/images/avatars/1.png') : require('@/assets/images/avatars/2.png')}`"
                    ></v-img>
                  </v-avatar>
                  <div style="font-size:10px">
                    {{ clanok.autor[0].jmeno }} {{ clanok.autor[0].prijmeni }}
                    {{ clanok.datum | moment("DD.MM.YYYY HH:mm") }}
                  </div>
                </div>
              </v-col>
              <v-col
                xl="10"
                lg="10"
                sm="10"
                md="10"
                xs="12"
              >
                <div
                  @mouseover="small_show_btn=index"
                  @mouseleave="small_show_btn=-1"
                >
                  <p class="text-xl font-weight-semibold primary--text mb-2">
                    {{ clanok.nazev }}
                    <span
                      v-show="(small_show_btn==index && $store.getters.getProfile._id === clanok.autor[0]._id) || (small_show_btn==index && $store.getters.getProfile.is_admin)"
                      style="float:right;"
                    >
                      <v-fab-transition>
                        <v-btn
                          color="primary"
                          fab
                          x-small
                          style="margin-right:5px"
                          @click="onEditFormClanky(clanok._id)"
                        >
                          <v-icon>{{ mdiPencil }}</v-icon>
                        </v-btn>
                      </v-fab-transition>
                      <v-fab-transition>
                        <v-btn
                          color="error"
                          fab
                          x-small
                          @click="onDeleteFormClanky(clanok._id)"
                        >
                          <v-icon>{{ mdiTrashCan }}</v-icon>
                        </v-btn>
                      </v-fab-transition>
                    </span>
                  </p>
                  <p v-html="clanok.text">
                  </p>
                </div>
              </v-col>
            </v-card-text>
            <div style="margin:auto;width:99%;border-bottom: 1px solid #C999FF;opacity:0.2"></div>
          </div>
          <div style="padding-top:15px;">
            <!-- STRANKOVANIE -->
            <v-pagination
              v-model="fetch_settings.paging.current_page"
              :length="fetch_settings.paging.total_pages"
              :total-visible="7"
              circle
              @input="handlePaging"
            >
            </v-pagination>
          </div>
        </div>
      </div>
    </v-card>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>

    <!-- FORM CLANKY -->
    <form-clanky
      :show="open_form_clanky"
      :title="`${item_id ? 'Upravit aktualitu' : 'Vložit novú aktualitu'}`"
      :type="1"
      :item_id="item_id"
      @close="onCloseFormClanky(...arguments)"
    >
    </form-clanky>

    <!-- ZMAZAT DIALOG -->
    <v-dialog
      v-model="open_dialog_zmazat"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h7">
          Upozornění
        </v-card-title>

        <v-card-text>
          Skutečne chcete odstranit aktualitu?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="open_dialog_zmazat = false"
          >
            Zrušit
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="deleteFormClanky()"
          >
            Odstranit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */

import axios from 'axios'
import latinize from 'latinize'
import {
  mdiPencil,
  mdiTrashCan,
} from '@mdi/js'
import SnackBar from '@/components/snackbar/index.vue'
import FormClanky from '@/components/form-clanky/index.vue'
import response from '@/mixins/response'

export default {
  components: {
    'snack-bar': SnackBar,
    'form-clanky': FormClanky,
  },
  mixins: [response],
  data() {
    return {
      loading: false,
      small_show_btn: -1,
      open_form_clanky: false,
      open_dialog_zmazat: false,
      clanky: [],
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      desktop_w: 1024,
      desktop_h: 768,
      boxH: 81,
      boxH1: 193,
      mdiPencil,
      mdiTrashCan,
      item_id: undefined, // _id clanku/aktuality, ktora sa ma editovat
      fetch_settings: {
        filter: {
          typ_zpravy: 1,
          active: true,
          smazane: false,
        },
        paging: {
          limit: 6,
          skip: 0,
          current_page: 1,
          total_pages: 1,
          total_records: 1,
        },
        sort: {
          datum: -1,
        },
      },
    }
  },
  watch: {
    '$store.state.global.filter_search': {
      handler(_newVal) {
        const filter = this.buildFilter(latinize(_newVal).toLowerCase())
        this.fetchAktuality(filter)
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    this.windowResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
  },
  mounted() {
    this.fetchAktuality()
  },
  methods: {
    windowResize() {
      this.desktop_w = window.innerWidth
      this.desktop_h = window.innerHeight
      this.calculateW()
    },
    calculateW() {
      if (this.desktop_h < 900) {
        this.boxH = this.desktop_w < 1587 ? 81 : 98
        this.boxH1 = 193
      } else {
        this.boxH = 212
        this.boxH1 = 312
      }
    },
    onEditFormClanky(p_id) {
      this.item_id = p_id
      this.open_form_clanky = true
    },
    onDeleteFormClanky(p_id) {
      this.item_id = p_id
      this.open_dialog_zmazat = true
    },
    deleteFormClanky() {
      this.open_dialog_zmazat = false
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/clanky/${this.item_id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'delete',
      }
      axios(config)
        .then(resp => {
          console.log('onDeleteFormClanky => ', resp)
          this.fetchAktuality()
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    onCloseFormClanky(p_param) {
      this.open_form_clanky = false
      this.item_id = undefined
      if (p_param.refresh !== false) {
        this.fetchAktuality()
      }
    },
    handlePaging(p_page) {
      // vypocitaj hodnotu skip a potom zavolaj nove nacitanie dat z DB
      const { limit } = this.fetch_settings.paging
      this.fetch_settings.paging.skip = (limit * p_page) - limit
      this.fetchAktuality()
    },
    buildFilter(_search) {
      const filter = { // default
        typ_zpravy: 1,
        active: true,
        smazane: false,
      }
      if (_search && _search.length > '') {
        const search = { $regex: `${_search}`, $options: 'i' }
        filter.$or = [
          { latin_nazev: search },
          { latin_text: search },
        ]
      }

      return filter
    },
    fetchAktuality(p_filter) {
      this.loading = true
      this.fetch_settings.filter = p_filter || this.fetch_settings.filter
      const body = JSON.stringify(this.fetch_settings)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/clanky/index`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.clanky = resp.data.db_data
          this.loading = false
          this.fetch_settings.paging.total_pages = resp.data.total_pages
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    isWoman(p_val) {
      /* eslint newline-before-return: "off" */

      if (p_val) {
        if (p_val.slice(p_val.length - 1) === 'á') {
          return true // iba ak je zena
        }
      }
      return false // default
    },
  },
}
</script>

<style lang="scss" scoped>
.news-card {
  position: relative;
  .news-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .news-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .news-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .news-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
