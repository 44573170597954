import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"700px"},model:{value:(_vm.dialog_show),callback:function ($$v) {_vm.dialog_show=$$v},expression:"dialog_show"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Vaše dostupné terminály")])]),_c(VCardText,[_c(VSelect,{attrs:{"items":_vm.terminals,"item-text":"hostname","item-value":"_id","hint":((_vm.select_terminal.hostname) + ", " + (_vm.select_terminal.stav) + ", " + (_vm.select_terminal.stav_text)),"label":"Zvolte terminál","return-object":"","single-line":""},model:{value:(_vm.select_terminal),callback:function ($$v) {_vm.select_terminal=$$v},expression:"select_terminal"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Zavřít ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.connectToTerminal()}}},[_vm._v(" Připojit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }