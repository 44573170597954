<template>
  <v-row>
    <v-col
      xl="6"
      lg="6"
      xs="12"
      sm="12"
      md="12"
    >
      <dashboard-aktuality></dashboard-aktuality>
    </v-col>
    <v-col
      xl="6"
      lg="6"
      xs="12"
      sm="12"
      md="12"
    >
      <v-row>
        <v-col
          xl="12"
          lg="12"
          xs="12"
          sm="12"
          md="12"
        >
          <dashboard-deviceinfo></dashboard-deviceinfo>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xl="12"
          lg="12"
          xs="12"
          sm="12"
          md="12"
        >
          <dashboard-videonavody></dashboard-videonavody>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import DashboardAktuality from './components/Aktuality.vue'
import DashboardDeviceInfo from './components/DeviceInfo.vue'
import DashboardVideonavody from './components/Videonavody.vue'

export default {
  components: {
    DashboardAktuality,
    'dashboard-deviceinfo': DashboardDeviceInfo,
    DashboardVideonavody,
  },
  data() {
    return {

    }
  },
  watch: {

  },
  created() {
    // this.throwError()
    // setTimeout(() => {
    //   if (this.$store.getters.getProfile.login === 'slovensko') {
    //   // redirect
    //     this.$router.push({ path: '/openai/index' })
    //   }
    // }, 1200)
  },
  methods: {
    throwError() {
      throw new Error('Sentry error test!') // ked sa zavola tato testovacia metoda, zobrazi sa to v Monitore na GitLabe
    },
  },
}
</script>

<style>
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
</style>
