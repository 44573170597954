<template>
  <div>
    <v-card
      class="news-card"
      style="max-height:315px"
    >
      <!-- OVLADACI PANEL -->
      <v-row class="ma-0 pa-0">
        <v-col cols="12">
          <v-card-title
            class="text-no-wrap pt-0 ps-0"
            style="width:100%;padding:0px 0px 8px 0px"
          >
            <v-col
              :cols="6"
              class="text-no-wrap pt-0 ps-0"
            >
              Váš ovládací panel
            </v-col>
            <v-col
              :cols="6"
              style="text-align:right"
              class="text-no-wrap pt-0 ps-0"
            >
              <!-- SPOJENIE NA LOCALHOSTE -->
              <v-icon
                v-if="localhost_alive"
                style="cursor:help;margin-right:10px;"
                :title="`${localhost_alive ? 'Spojení s velin desktopem/terminálem na localhostu je v pořádku.' : 'Zjištěný problém, komunikace na localhostu selhala!'}`"
                dark
                :color="`${localhost_alive ? 'success' : '#EEEEEE'}`"
                size="25"
              >
                {{ localhost_alive ? icons.mdiLanConnect : icons.mdiLanDisconnect }}
              </v-icon>
              <!-- JE WIFI PRIPOJENA? -->
              <v-icon
                v-if="localhost_alive"
                style="cursor:help;margin-right:10px;"
                :title="`${wifi === 'unknown' ? 'Wifi je odpojeno.' : 'WiFi je připojeno do bezdrátové sítě!'}`"
                dark
                :color="`${wifi === 'unknown' ? '#EEEEEE' : 'success'}`"
                size="25"
              >
                {{ wifi === 'unknown' ? icons.mdiAccessPoint : icons.mdiAccessPointCheck }}
              </v-icon>
            </v-col>
          </v-card-title>

          <v-card-subtitle
            class="mb-4 mt-n5 pt-0 ps-0"
            style="margin-left: 10px;"
          >
            Důležité informace při komunikaci s ítečkáři
          </v-card-subtitle>

          <v-card-text>
            <!-- 0. riadok, iba ak localhost nezije -->
            <v-row v-if="!localhost_alive">
              <v-col
                cols="12"
                md="12"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="secondary"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="#FFD600"
                    size="30"
                  >
                    {{ icons.mdiAlert }}
                  </v-icon>
                </v-avatar>
                <div
                  class="ms-3"
                  style="padding:0px 0px 0px 8px"
                >
                  Podpůrná utilita pro Velín, je na vašem PC/NB nedostupná. Některé funkce Velinu budou nedostupné nebo nebudou fungovat správně.
                  Chcete-li problém vyřešit, zkuste restartovat vaše zařízení nebo kontaktujte IT podporu červeným tlačítkem POMOC!
                </div>
              </v-col>
            </v-row>
            <!-- 1. riadok (dva stlpce) -->
            <v-row v-if="localhost_alive">
              <v-col
                cols="6"
                md="6"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="secondary"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiDevices }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    TYP
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ type === 'unknown' ? 'zjišťuji...' : type === 'local' ? 'desktop' : 'terminal' }}
                  </h3>
                </div>
              </v-col>
              <v-col
                cols="6"
                md="6"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="success"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiDesktopClassic }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    HOSTNAME
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ hostname === 'unknown' ? 'zjišťuji...' : hostname }}
                  </h3>
                </div>
              </v-col>
            </v-row>
            <!-- 2. riadok (dva stlpce) -->
            <v-row v-if="localhost_alive">
              <v-col
                cols="6"
                md="6"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="warning"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiAccountOutline }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    USERNAME
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ username === 'unknown' ? 'zjišťuji...' : username }}
                  </h3>
                </div>
              </v-col>
              <v-col
                cols="6"
                md="6"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="info"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiIpNetwork }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    IP ADRESA
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ ipv4 === 'unknown' ? (wifi === 'unknown' ? 'zjišťuji...' : wifi) : ipv4 }}
                  </h3>
                </div>
              </v-col>
            </v-row>
            <v-divider style="margin-top:35px;margin-bottom:15px"></v-divider>
            <!-- 3. riadok (info a button) -->
            <v-row>
              <v-col
                cols="3"
                style="text-align:left"
              >
                <!-- REFRESH, uz zije localhost? -->
                <v-btn
                  v-if="!localhost_alive"
                  style="margin-right: 10px"
                  x-small
                  fab
                  @click="getID()"
                >
                  <v-icon
                    title="Zkusit znovu kontaktovat velín desktop"
                    color="#FFA726"
                    dark
                  >
                    {{ icons.mdiSyncAlert }}
                  </v-icon>
                </v-btn>
                <!-- CONSOLE -->
                <v-btn
                  v-if="localhost_alive"
                  style="margin-right: 10px"
                  x-small
                  fab
                  :color="`${localhost_alive ? 'primary' : '#EEEEEE'}`"
                  :disabled="localhost_alive ? false : true"
                  @click="runCommand('console')"
                >
                  <v-icon
                    title="Otevřít konzolu"
                    dark
                  >
                    {{ icons.mdiConsole }}
                  </v-icon>
                </v-btn>
                <!-- PRINTERS -->
                <v-btn
                  v-if="localhost_alive"
                  x-small
                  fab
                  :color="`${localhost_alive ? 'primary' : '#EEEEEE'}`"
                  :disabled="localhost_alive ? false : true"
                  @click="runCommand('printers')"
                >
                  <v-icon
                    title="Otevřít seznam tiskáren"
                    dark
                  >
                    {{ icons.mdiPrinterSettings }}
                  </v-icon>
                </v-btn>
              </v-col>
              <!-- tl.: POMOC, PRIPOJIT K TERMINALU -->
              <v-col
                cols="9"
                style="text-align:right"
              >
                <v-btn
                  small
                  color="error"
                  @complete="sendSOS(...arguments)"
                  @click="handleSOS()"
                >
                  {{ $vuetify.breakpoint.lgAndDown ? 'Pomoc!' : 'Potřebuji pomoct!' }}
                </v-btn>
                <!-- TLACITKO - Pripojit k terminalu -->
                <!-- Zobraz iba ak sa jedna o DESKTOP PC/NB -->
                <v-btn
                  v-if="type === 'local' && localhost_alive"
                  :loading="btn_loading"
                  style="margin-left: 10px"
                  small
                  color="success"
                  @click="handleConnect()"
                >
                  Připojit k terminálu
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <!-- SOS DIALOG -->
    <sos-form
      :show="dialog_sos"
      @completedSOS="sendSOS(...arguments)"
      @closeSOS="dialog_sos=false"
    ></sos-form>

    <!-- VYBER TERMINALU -->
    <terminal-dialog
      :show="dialog_available_terminal"
      :terminals="terminals"
      @selected="connectToTerminal(...arguments)"
      @close="dialog_available_terminal=false"
    ></terminal-dialog>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
/* eslint no-underscore-dangle: "off" */
/* eslint-disable no-undef */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */

import {
  mdiAccountOutline,
  mdiDevices,
  mdiDesktopClassic,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiAccessPoint,
  mdiAccessPointCheck,
  mdiIpNetwork,
  mdiLanConnect,
  mdiLanDisconnect,
  mdiConsole,
  mdiPrinterSettings,
  mdiAlert,
  mdiSyncAlert,
} from '@mdi/js'
import axios from 'axios'
import device from '@/mixins/device'
import SnackBar from '@/components/snackbar/index.vue'
import terminalDialog from '@/components/terminal-dialog/index.vue'
import sosForm from '@/components/form-sos/index.vue'

export default {
  components: {
    'snack-bar': SnackBar,
    'terminal-dialog': terminalDialog,
    'sos-form': sosForm,
  },
  mixins: [device],
  data() {
    return {
      // icons
      icons: {
        mdiDotsVertical,
        mdiDesktopClassic,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiDevices,
        mdiAccessPoint,
        mdiAccessPointCheck,
        mdiIpNetwork,
        mdiLanConnect,
        mdiLanDisconnect,
        mdiConsole,
        mdiPrinterSettings,
        mdiAlert,
        mdiSyncAlert,
      },
      btn_loading: false,
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      user: [], // cely objekt uzivatel + dostupne terminaly
      terminals: [], // iba dostupne terminaly
      dialog_available_terminal: false,
      dialog_sos: false,
    }
  },
  watch: {
    localhost_alive(is_alive) {
      // console.log('localhost_alive =>', is_alive)
      if (is_alive) {
        this.getData()
      }
    },
  },
  created() {
    this.getID()
  },
  methods: {
    getData() {
      this.getID()
      this.getType()
      this.getPlatform()
      this.getHostname()
      this.getUsername()
      this.getInfo()
    },
    handleSOS() {
      this.dialog_sos = true
    },
    sendSOS(p_form) {
      const data = p_form
      data.login_email = this.$store.getters.getProfile.email
      data.login_fullname = `${this.$store.getters.getProfile.jmeno}  ${this.$store.getters.getProfile.prijmeni}`
      data.login_phone = this.$store.getters.getProfile.phone
      data.login_jobtitle = this.$store.getters.getProfile.jobTitle
      data.hostname = this.hostname
      data.username = this.username
      data.type = this.type
      data.platform = this.platform
      data.localhost_alive = this.localhost_alive
      data.ipv4 = this.ipv4
      data.ipv4_mac = this.ipv4_mac
      data.wifi = this.wifi
      data.wifi_mac = this.wifi_mac
      data.device_ID = this.device_ID
      data.cpu_model = this.cpu_model
      data.cpu_count = this.cpu_count
      data.ram_total = this.ram_total
      data.ram_free = this.ram_free

      // ---

      const body = JSON.stringify(data)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/tool/sos/send`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.snack_text = 'Formulář byl úspěšně odeslán na IT podporu.'
          this.snack_color = 'success'
          this.snackbar = true
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    handleConnect() {
      this.getTerminals()
    },
    getTerminals() {
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/tool/terminal/${this.$store.getters.getProfile._id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          this.user = resp.data.db_data[0]
          this.terminals = resp.data.db_data[0].terminals

          // ak ma moznost vyberat, tak zobraz dialog s vyberom terminalov

          if (this.$store.getters.getProfile.povol_vyber) {
            this.dialog_available_terminal = true
          } else {
            // inak rovno connectuj, prvy terminal v poradi, ktory je aktivny
            for (let index = 0; index < this.terminals.length; index++) {
              const terminal = this.terminals[index]
              if (terminal.aktivni === true) {
                this.connectToTerminal(terminal)
                break
              }
            }
          }
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    connectToTerminal(p_terminal) {
      this.btn_loading = true
      const body = JSON.stringify({
        device_ID: this.device_ID,
        terminal_id: p_terminal._id,
        username: `NOSLUS\\${this.user.login}`,
        password: this.user.pass,
        ip_adresa: p_terminal.ip_adresa,
        port: p_terminal.port,
      })
      const config = {
        url: `${process.env.VUE_APP_DESKTOP_API}/terminal/connect`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          setTimeout(() => {
            this.btn_loading = false
          }, 3000)
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
          this.btn_loading = false
        })
    },
    runCommand(p_action) {
      let custom_url = null
      let custom_body = null
      let custom_method = null
      switch (p_action) {
        case 'console':
          custom_url = `${process.env.VUE_APP_DESKTOP_API}/tool/console/${this.$store.getters.getProfile.login}`
          custom_body = JSON.stringify({})
          custom_method = 'get'
          break
        case 'printers':
          custom_url = `${process.env.VUE_APP_DESKTOP_API}/tool/printers/${this.$store.getters.getProfile.login}`
          custom_body = JSON.stringify({})
          custom_method = 'get'
          break
        default:
          return
      }

      // ---

      const config = {
        url: custom_url,
        data: custom_body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: custom_method,
      }
      axios(config)
        .then(resp => {

        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.news-card {
  position: relative;
  overflow-y: scroll;
  .news-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .news-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .news-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .news-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
