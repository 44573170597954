<template>
  <div>
    <v-card
      :loading="loading"
      class="news-card"
    >
    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <v-card-title class="text-no-wrap pt-1 ps-2">
          Videonávod
        </v-card-title>

        <v-card-subtitle class="mb-8 mt-n5">
          Naposledy přidaný videonávod
        </v-card-subtitle>

        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
          <video-card :video="last_video"></video-card>
        </v-card-text>
      </v-col>
    </v-row>
    </v-card>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: ["off", { "allow": ["__place"] }] */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */

import axios from 'axios'
import response from '@/mixins/response'
import SnackBar from '@/components/snackbar/index.vue'
import Video from '../../pages/videonavody/components/Video.vue'

export default {
  components: {
    'snack-bar': SnackBar,
    'video-card': Video,
  },
  mixins: [response],
  data() {
    return {
      loading: true,
      desktop_w: 1024,
      desktop_h: 768,
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      last_video: {
        linkObrazek: '',
      },
      fetch_settings: {
        filter: {
          active: true,
        },
        paging: {
          limit: 1,
          skip: 0,
          current_page: 1,
          total_pages: 1,
          total_records: 1,
        },
        sort: {
          datum: -1,
        },
      },
    }
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    this.fetchVideonavody()
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
  },
  methods: {
    fetchVideonavody() {
      this.loading = true
      const body = JSON.stringify(this.fetch_settings)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/videonavody/index`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.last_video = resp.data.db_data[0]
          this.loading = false
          this.fetch_settings.paging.total_pages = resp.data.total_pages
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.news-card {
  position: relative;
  overflow-y: scroll;
  .news-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .news-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .news-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .news-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
