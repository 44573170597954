<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog_show"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Vaše dostupné terminály</span>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="select_terminal"
            :items="terminals"
            item-text="hostname"
            item-value="_id"
            :hint="`${select_terminal.hostname}, ${select_terminal.stav}, ${select_terminal.stav_text}`"
            label="Zvolte terminál"
            return-object
            single-line
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()"
          >
            Zavřít
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="connectToTerminal()"
          >
            Připojit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */

import response from '@/mixins/response'

export default {
  mixins: [response],
  props: ['show', 'terminals'],
  data() {
    return {
      dialog_show: false,
      select_terminal: {},
    }
  },
  computed: {

  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        this.dialog_show = val
      },
    },
  },
  created() {
    this.dialog_show = this.show
  },
  mounted() {

  },
  methods: {
    connectToTerminal() {
      this.$emit('selected', this.select_terminal)
      this.closeDialog()
    },
    closeDialog() {
      this.$emit('close')
    },
  },
}
</script>
