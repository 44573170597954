import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"news-card",attrs:{"loading":_vm.loading}},[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCardTitle,{staticClass:"text-no-wrap pt-1 ps-2"},[_vm._v(" Videonávod ")]),_c(VCardSubtitle,{staticClass:"mb-8 mt-n5"},[_vm._v(" Naposledy přidaný videonávod ")]),_c(VCardText,{staticClass:"d-flex align-center mt-2 pb-2 ps-2"},[_c('video-card',{attrs:{"video":_vm.last_video}})],1)],1)],1)],1),_c('snack-bar',{attrs:{"snackbar":_vm.snackbar,"color":_vm.snack_color,"text":_vm.snack_text},on:{"close":function($event){_vm.snackbar=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }