/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line object-curly-newline
/* eslint arrow-body-style: ["off", "always"] */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
import axios from 'axios'

export default {
  data() {
    return {
      type: 'unknown',
      platform: 'unknown',
      hostname: 'unknown',
      username: 'unknown',
      localhost_alive: false, // true|false => ak je dostupny dektop/terminal na localhoste:19900 hodnota bude true
      ipv4: 'unknown',
      ipv4_mac: 'unknown',
      wifi: 'unknown',
      wifi_mac: 'unknown',
      device_ID: 'unknown',
      cpu_model: 'unknown',
      ram_total: 'unknown',
      ram_free: 'unknown',
    }
  },
  created() {
    console.log('mixins device loaded...')
  },
  methods: {
    getDataFromLocal() { // v pripade, ze localhost nebezi, nacitaju sa ulozene dala z locastorage(ak uz aspon raz localhost bezal, budu tam)
      this.device_ID = localStorage.getItem('device_ID') // nacitaj z horsich casov :)
      this.type = localStorage.getItem('type') // nacitaj z horsich casov :)
      this.platform = localStorage.getItem('platform') // nacitaj z horsich casov :)
      this.hostname = localStorage.getItem('hostname') // nacitaj z horsich casov :)
      this.username = localStorage.getItem('username') // nacitaj z horsich casov :)
      this.ipv4 = localStorage.getItem('ipv4') // nacitaj z horsich casov :)
      this.ipv4_mac = localStorage.getItem('ipv4_mac') // nacitaj z horsich casov :)
      this.cpu_model = localStorage.getItem('cpu_model') // nacitaj z horsich casov :)
      this.cpu_count = localStorage.getItem('cpu_count') // nacitaj z horsich casov :)
      this.ram_total = localStorage.getItem('ram_total') // nacitaj z horsich casov :)
    },
    getID() { // iba tato funkcia zistuje ci je localhost spusteny
      const config = {
        url: `${process.env.VUE_APP_DESKTOP_API}/id`,
        crossdomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          // console.log('[mixins/device.js] id =>', resp.data)

          this.localhost_alive = true
          this.device_ID = resp.data

          // uloz na horsie casy do localstorage
          localStorage.setItem('device_ID', this.device_ID)
        })
        .catch(err => {
          this.localhost_alive = false
          this.device_ID = localStorage.getItem('device_ID') // nacitaj z horsich casov :)
          this.getDataFromLocal()
          console.warn('[mixins/device.js] getID ! err', err)
        })
    },
    getType() {
      const config = {
        url: `${process.env.VUE_APP_DESKTOP_API}/desktop`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          // console.log('[mixins/device.js] type =>', resp.data)

          this.type = resp.data

          // uloz na horsie casy do localstorage
          localStorage.setItem('type', this.type)
        })
        .catch(err => {
          this.type = localStorage.getItem('type') // nacitaj z horsich casov :)
          console.warn('[mixins/device.js] getType ! err', err)
        })
    },
    getPlatform() {
      const config = {
        url: `${process.env.VUE_APP_DESKTOP_API}/platform`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          // console.log('[mixins/device.js] platform =>', resp.data)

          this.platform = resp.data

          // uloz na horsie casy do localstorage
          localStorage.setItem('platform', this.platform)
        })
        .catch(err => {
          this.platform = localStorage.getItem('platform') // nacitaj z horsich casov :)
          console.warn('[mixins/device.js] getPlatform ! err', err)
        })
    },
    getHostname() {
      const config = {
        url: `${process.env.VUE_APP_DESKTOP_API}/hostname`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          // console.log('[mixins/device.js] hostname =>', resp.data)

          this.hostname = resp.data

          // uloz na horsie casy do localstorage
          localStorage.setItem('hostname', this.hostname)
        })
        .catch(err => {
          this.hostname = localStorage.getItem('hostname') // nacitaj z horsich casov :)
          console.warn('[mixins/device.js] getHostname ! err', err)
        })
    },
    getUsername() {
      const config = {
        url: `${process.env.VUE_APP_DESKTOP_API}/username`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          // console.log('[mixins/device.js] username =>', resp.data)

          this.username = resp.data

          // uloz na horsie casy do localstorage
          localStorage.setItem('username', this.username)
        })
        .catch(err => {
          this.username = localStorage.getItem('username') // nacitaj z horsich casov :)
          console.warn('[mixins/device.js] getUsername ! err', err)
        })
    },
    getInfo() { // vrati hodnoty o LAN/WIFI/CPU/RAM atd...
      const config = {
        url: `${process.env.VUE_APP_DESKTOP_API}/info`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          // console.log('[mixins/device.js] info =>', resp.data)

          // LAN - ipv4

          for (const property in resp.data.networkInterfaces) {
            if (property && property.toString().indexOf('Ethernet') > -1) {
              const arr_ipv4 = resp.data.networkInterfaces[property]
              for (let index = 0; index < arr_ipv4.length; index++) {
                const lan = arr_ipv4[index]
                if (lan.family.toLowerCase() === 'ipv4') {
                  this.ipv4 = lan.address
                  this.ipv4_mac = lan.mac

                  // uloz na horsie casy do localstorage
                  localStorage.setItem('ipv4', this.ipv4)
                  localStorage.setItem('ipv4_mac', this.ipv4_mac)
                }
              }
            }
          }

          // WIFI - ipv4

          if (resp.data.networkInterfaces['Wi-Fi']) {
            const arr_wifi = resp.data.networkInterfaces['Wi-Fi']
            for (let index = 0; index < arr_wifi.length; index++) {
              const lan = arr_wifi[index]
              if (lan.family.toLowerCase() === 'ipv4') {
                this.wifi = lan.address
                this.wifi_mac = lan.mac

                // uloz na horsie casy do localstorage
                localStorage.setItem('wifi', this.wifi)
                localStorage.setItem('wifi_mac', this.wifi_mac)
              }
            }
          }

          // CPU - model

          if (resp.data.cpus && resp.data.cpus.length > 0) {
            this.cpu_model = resp.data.cpus[0].model

            // uloz na horsie casy do localstorage
            localStorage.setItem('cpu_model', this.cpu_model)
          }

          // CPU - pocet jadier

          if (resp.data.cpus && resp.data.cpus.length > 0) {
            this.cpu_count = resp.data.cpus.length

            // uloz na horsie casy do localstorage
            localStorage.setItem('cpu_count', this.cpu_count)
          }

          // RAM - total

          if (resp.data.totalmem) {
            this.ram_total = resp.data.totalmem

            // uloz na horsie casy do localstorage
            localStorage.setItem('ram_total', this.ram_total)
          }

          // RAM - free

          if (resp.data.freemem) {
            this.ram_free = resp.data.freemem
          }
        })
        .catch(err => {
          this.ipv4 = localStorage.getItem('ipv4') // nacitaj z horsich casov :)
          this.ipv4_mac = localStorage.getItem('ipv4_mac') // nacitaj z horsich casov :)
          this.cpu_model = localStorage.getItem('cpu_model') // nacitaj z horsich casov :)
          this.cpu_count = localStorage.getItem('cpu_count') // nacitaj z horsich casov :)
          this.ram_total = localStorage.getItem('ram_total') // nacitaj z horsich casov :)
          this.ram_free = '-'
          console.warn('[mixins/device.js] getIPaddress ! err', err)
        })
    },
  },
}
